<template>
    <div class="page-single">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('关于我们')" left-arrow @click-left="onClickLeft">
        </van-nav-bar>
        <div class="page-banner">
            <img src="../../assets/pictures/page-banner1.jpg" alt="">
        </div>
        <div class="page-iframe">
			<div class="page-top"></div>
            <div class="page-body" v-html="html">
            </div>
			<div class="page-bottom"></div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                name: '',
                html: '',
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            getData() {
                this.$axios
                    .get('/help/get_content?type=10')
                    .then((res) => {
                        this.html = res.data;
                    });
            },
        },
		mounted() {
			this.getData();
		}

    }
</script>